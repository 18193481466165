import { FC } from 'react'
import s from './PlayingField.module.scss'
import classNames from 'classnames'
import History, { IRoundResult } from './History/History'
import { GameResult } from '../Game'

const PlayingField: FC<IProps> = ({ gameResult, odd, history }) => {

    return (
        <div className={s.wrapper}>
            <History results={history} className={s.history} />
            <div className={classNames(
                s.odd,
                { [s.crash]: gameResult === 'lose' },
                { [s.win]: gameResult === 'win' },
            )}>
                {odd.toFixed(2)}x
            </div>
        </div>
    )
}

export default PlayingField

interface IProps {
    gameResult: GameResult
    odd: number
    history: IRoundResult[]
}