import {FC, useContext, useEffect, useState} from 'react'
import s from './Control.module.css'
import Button from '../../Button/Button'
import Input from '../../Input/Input'
import Checkbox from '../../Checkbox/Checkbox'
import InitialStateContext from '../../../context/InitialStateContext'
import { checkCurrencyCode } from '../../../utils/checkCurrencyCode'
import {useTranslation} from "../../../hooks/useTranslation";

const Control: FC<IProps> = ({
     amount,
     setAmount,
     targetMultiplier,
     setTargetMultiplier,
     isAutobet,
     setIsAutobet,
     numberOfRounds,
     setNumberOfRounds,
     spin,
     runAutobet,
     isAutobetRunning,
     isGame,
}) => {
    const { t } = useTranslation()
    const [initialState] = useContext(InitialStateContext)

    const changeValue = (
        name: 'amount' | 'targetMultiplier' | 'numberOfRounds',
        prevValue: string,
        newValue: string | ((prev: string) => string)
    ) => {
        if (typeof newValue !== 'string') {
            newValue = newValue(prevValue)
        }

        // validate min target multiplier
        if (name === 'targetMultiplier' && newValue !== '' && +newValue < 1) {
            return
        }

        // validate number of rounds
        if (name === 'numberOfRounds' && ((newValue !== '' && +newValue < 1) || (+newValue > 10000))) {
            return
        }

        switch (name) {
            case 'amount' :
                setAmount(newValue)
                localStorage.setItem(process.env.REACT_APP_AMOUNT_KEY as string, newValue)
                break
            case 'targetMultiplier':
                setTargetMultiplier(newValue)
                localStorage.setItem(process.env.REACT_APP_TARGET_MULTIPLIER_KEY as string, newValue)
                break
            case 'numberOfRounds':
                setNumberOfRounds(newValue)
                break
        }
    }

    /* Bet amount */
    const divideAmountValidation = (amount: string): string => {
        if (+amount / 2 >= initialState?.limits?.minBet) {
            return (+amount / 2).toFixed(initialState?.currencyPrecision).toString()
        }

        return amount
    }

    const multiplyAmountValidation = (amount: string): string => {
        if (+amount * 2 <= initialState?.limits?.maxBet) {
            return (+amount * 2).toFixed(initialState?.currencyPrecision).toString()
        }

        return amount
    }

    const amountActions = [
        { text: '½', action: () => changeValue('amount', amount, divideAmountValidation(amount)) },
        { text: '2×', action: () => changeValue('amount', amount, multiplyAmountValidation(amount)) },
        { text: t('MAX'), action: () => changeValue(
                'amount',
                amount,
                initialState?.limits?.maxBet.toFixed(initialState?.currencyPrecision),
        )},
    ]
    /* ----------- */

    const [winChance, setWinChance] = useState('0')

    useEffect(() => {
        let res = (1 / +targetMultiplier) * 99
        setWinChance(res.toFixed(2) + '%')
    }, [targetMultiplier])

    /* Number Of Rounds */
    const numberOfRoundsActions = [
        { text: '20', action: () => setNumberOfRounds('20') },
        { text: '50', action: () => setNumberOfRounds('50') },
        { text: '100', action: () => setNumberOfRounds('100') },
    ]
    /* ----------- */

    useEffect(() => {
        if (!initialState) return

        const amount = localStorage.getItem(process.env.REACT_APP_AMOUNT_KEY as string)
        const multiplier = localStorage.getItem(process.env.REACT_APP_TARGET_MULTIPLIER_KEY as string)

        setAmount(
            amount ?? initialState?.limits?.minBet
                .toFixed(initialState?.currencyPrecision)
                .toString()
        )

        if (multiplier) {
            setTargetMultiplier(multiplier)
        }

    }, [initialState])

    return (
        <div className={s.control}>
            {!isAutobet && (
                <Button
                    onClick={() => spin(+amount, +targetMultiplier)}
                    disabled={!Object.keys(initialState).length || isGame}
                    clickSoundExcept
                >
                    {t('BET')}
                </Button>
            )}
            {isAutobet && (
                <Button
                    onClick={runAutobet}
                    disabled={isAutobetRunning}
                    clickSoundExcept
                >
                    {t('STARTAUTOBET')}
                </Button>
            )}

            <Input
                value={amount}
                onChange={value =>
                    changeValue('amount', amount, value)
                }
                label={t('BETAMOUNT')}
                actions={amountActions}
                currency={checkCurrencyCode(initialState?.currencyCode)}
                min={initialState?.limits?.minBet}
                max={initialState?.limits?.maxBet}
                precision={initialState?.currencyPrecision}
                autoReplace={[
                    [/[^.\d]+/g, ""],
                    [/^([^\.]*\.)|\./g, '$1'],
                ]}
                readOnly={isAutobetRunning}
            />

            <div className={s.grid}>
                <Input
                    value={targetMultiplier}
                    onChange={value =>
                        changeValue('targetMultiplier', targetMultiplier, value)
                    }
                    label={t('TARGETMULTIPLIER')}
                    controls
                    step={0.01}
                    min={1.01}
                    max={100000}
                    precision={2}
                    autoReplace={[
                        [/[^.\d]+/g, ""],
                        [/^([^\.]*\.)|\./g, '$1'],
                    ]}
                    maxDecimalsPlace={6}
                    readOnly={isAutobetRunning}
                />
                <Input
                    value={winChance}
                    readOnly
                    onChange={() => {}}
                    label={t('WINCHANCE')}
                />
            </div>

            <div className={s.autobet}>
                <Input
                    value={numberOfRounds}
                    onChange={value =>
                        changeValue('numberOfRounds', numberOfRounds, value)
                    }
                    label={t('NUMROUNDS')}
                    actions={numberOfRoundsActions}
                    max={10000}
                    autoReplace={[[/[^\d]/ig, '']]}
                    readOnly={isAutobet}
                />
                <Checkbox
                    checked={isAutobet}
                    onChange={setIsAutobet}
                    className={s.checkbox}
                    clickSoundExcept
                >
                    {t('AUTOBET')}
                </Checkbox>
            </div>
        </div>
    )
}

export default Control

interface IProps {
    amount: string
    setAmount: (value: ((prev: string) => string) | string) => void
    targetMultiplier: string
    setTargetMultiplier: (value: ((prev: string) => string) | string) => void
    isAutobet: boolean
    setIsAutobet: (value: boolean) => void
    numberOfRounds: string
    setNumberOfRounds: (value: ((prev: string) => string) | string) => void
    spin: (amount: number, mines: number) => void
    runAutobet: () => void
    isAutobetRunning: boolean
    isGame: boolean
}
