import s from './RulesPopup.module.css'
import { FC } from 'react'
import { useTranslation } from '../../../hooks/useTranslation'

const RulesPopup: FC = () => {
    const { t } = useTranslation()

    return (
        <div className={s.popup}>
            <p className={s.title}>{t('GAMEINFO')}</p>
            <p className={s.label}>{t('RULES')}</p>
            <div dangerouslySetInnerHTML={{ __html: t('LIMBORULES') }} />
        </div>
    )
}

export default RulesPopup
