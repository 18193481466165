import { FC, useEffect, useState } from 'react'
import { svgIcons } from '../../../assets/svgIcons'
import s from './ProvablyFairnessPopup.module.css'
import Input from '../../Input/Input'
import Button from '../../Button/Button'
import Api from '../../../api'
import { IOldHashAndKey } from '../../../types/Messages'
import {useTranslation} from "../../../hooks/useTranslation";

const ProvablyFairnessModal: FC<IProps> = ({ lastRound }) => {
    const { t } = useTranslation()

    if (!lastRound) return <></>

    const [nextHash, setNextHash] = useState('')

    useEffect(() => {
        setNextHash('')
    }, [lastRound])

    const calculate = async () => {
        const { result } = await Api().result.getResult(lastRound.key, lastRound.salt)
        setNextHash(result)
    }

    return (
        <>
            <div className={s.head}>
                <p className={s.title}>
                    {svgIcons.fairness}
                    {t('TITLE')}
                </p>
                <p className="text-center">
                    {/*Last Round ID: {lastRound.roundId}*/}
                </p>
                <p className={s.odd}>{lastRound.odd}×</p>
                <div className={s.grid}>
                    <Input
                        value={lastRound.key ?? ''}
                        onChange={() => {}}
                        label={t('KEY')}
                        inputBackground="#534F4F"
                        canCopy
                        readOnly
                    />
                    <Input
                        value={lastRound.salt ?? ''}
                        onChange={() => {}}
                        label={t('SALT')}
                        inputBackground="#534F4F"
                        canCopy
                        readOnly
                    />
                    <Input
                        value={lastRound.hash}
                        onChange={() => {}}
                        label={t('HASHCODE')} className={s.full}
                        inputBackground="#534F4F"
                        canCopy
                        readOnly
                    />
                </div>
                <Button onClick={() => calculate()} className={s.calculateBtn}>
                    {t('CALCULATE')}
                </Button>
            </div>

            {nextHash && (
                <div className={s.content}>
                    <p className="text-center">
                        {t('NEXTROUND')}
                    </p>
                    <Input
                        value={nextHash}
                        onChange={setNextHash}
                        label={t('HASHCODE')} className={s.nextHash}
                        inputBackground="#534F4F"
                        canCopy
                    />
                    {/*<p>Hash Code ?</p>*/}
                </div>
            )}
        </>
    )
}

export default ProvablyFairnessModal

interface IProps {
    lastRound: IOldHashAndKey
}