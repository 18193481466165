import { useCallback, useEffect, useMemo, useState } from 'react'
import './App.css'
import { Route, Routes, useSearchParams } from 'react-router-dom'
import HomePage from './pages/HomePage'
import InitialStateContext from './context/InitialStateContext'
import BalanceContext from './context/BalanceContext'
import GameConfigContext, {IGameConfig} from './context/GameConfigContext'
import {InitialState, IOldHashAndKey} from './types/Messages'
import PopupsContext, { IPopups } from './context/PopupsContext'
import ErrorContext from './context/ErrorContext'
import Error from './components/Error/Error'
import OldHashAndKeyContext from './context/OldHashAndKeyContext'
import Api from "./api";
import { Translations } from './hooks/useTranslation'

const App = () => {
    const [params] = useSearchParams()
    const gameName = params.get('GameName')
    const culture = params.get('Culture')

    const [initialState, setInitialState] = useState({} as InitialState)
    const [balance, setBalance] = useState(0)

    const isMuteDefault: boolean = useMemo(() => {
        const str = localStorage.getItem(process.env.REACT_APP_MUTE_KEY ?? '')
        return str ? JSON.parse(str) : false
    }, [])

    const isTurboModeDefault: boolean = useMemo(() => {
        const str = localStorage.getItem(process.env.REACT_APP_TURBO_MODE_KEY ?? '')
        return str ? JSON.parse(str) : false
    }, [])

    const [gameConfig, setGameConfig] = useState<IGameConfig>({
        isMute: isMuteDefault,
        isTurboMode: isTurboModeDefault,
        translations: null,
    })

    const changeGameConfig = useCallback((param: keyof IGameConfig, value: boolean | Translations) => {
        setGameConfig(prev => ({ ...prev, [param]: value }))
    }, [])

    const [popus, setPopus] = useState<IPopups>({
        provablyFairness: false,
        rules: false,
    })

    useEffect(() => {
        if (Object.values(popus).some(value => !!value)) {
            document.body.style.overflowY = 'hidden'
        } else {
            document.body.style.overflowY = 'auto'
        }
    }, [popus])

    const [oldHashAndKey, setOldHashAndKey] = useState<IOldHashAndKey | null>(null)

    const [err, setErr] = useState('')

    useEffect(() => {
        const fetchData = async () => {
            if (!culture || !gameName) return
            try {
                const { result } = await Api().translation.getTranslations(
                    culture,
                    gameName,
                )
                changeGameConfig('translations', result)
            } catch (e) {
                //
            }
        }
        fetchData()
    }, [culture, gameName])

    return (
        <InitialStateContext.Provider value={[initialState, setInitialState]}>
            <BalanceContext.Provider value={[balance, setBalance]}>
                <GameConfigContext.Provider value={[gameConfig, changeGameConfig]}>
                    <PopupsContext.Provider value={[popus, setPopus]}>
                        <OldHashAndKeyContext.Provider value={[oldHashAndKey, setOldHashAndKey]}>
                            <ErrorContext.Provider value={[err, setErr]}>
                                <Error err={err} setErr={setErr} />
                                <Routes>
                                    <Route path="/" element={<HomePage />}/>
                                </Routes>
                            </ErrorContext.Provider>
                        </OldHashAndKeyContext.Provider>
                    </PopupsContext.Provider>
                </GameConfigContext.Provider>
            </BalanceContext.Provider>
        </InitialStateContext.Provider>
    )
}

export default App
