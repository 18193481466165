import { useCallback, useContext, useEffect, useState } from 'react'
import s from './Game.module.css'
import PlayingField from './PlayingField/PlayingField'
import Control from './Control/Control'
import { useSearchParams } from 'react-router-dom'
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr'
import { InitialState, SpinResult } from '../../types/Messages'
import InitialStateContext from '../../context/InitialStateContext'
import BalanceContext from '../../context/BalanceContext'
import GameConfigContext from '../../context/GameConfigContext'
import Api from '../../api'
import Popup from '../Popup/Popup'
import { useSounds } from '../../utils/useSounds'
import PopupsOpenContext, { IPopups } from '../../context/PopupsContext'
import ProvablyFairnessModal from './ProvablyFairnessPopup/ProvablyFairnessPopup'
import RulesPopup from './RulesPopup/RulesPopup'
import ErrorContext from '../../context/ErrorContext'
import yo from '../../assets/yo.png'
import OldHashAndKeyContext from '../../context/OldHashAndKeyContext'
import {randomOdd} from "../../utils/randomOdd";
import { IRoundResult } from './PlayingField/History/History'
import {useTranslation} from "../../hooks/useTranslation";

const Game = () => {
    const { t } = useTranslation()
    const [, setErr] = useContext(ErrorContext)

    const { betSound, winSound, autoplaySound, crashSound, clickSound} = useSounds()

    const [params] = useSearchParams()
    const partnerId = params.get('PartnerId')
    const gameName = params.get('GameName')
    const oneTimeToken = params.get('OneTimeToken')
    const mode = params.get('Mode')

    const [connection, setConnection] = useState<HubConnection | null>(null)

    const [balance, setBalance] = useContext(BalanceContext)
    const [initialState, setInitialState] = useContext(InitialStateContext)

    const [{ isMute, isTurboMode }] = useContext(GameConfigContext)
    const [popupOpen, setPopupOpen] = useContext(PopupsOpenContext)

    const openPopup = useCallback(
        (popup: keyof IPopups, value: boolean) => {
            setPopupOpen(prev => ({
                ...prev,
                [popup]: value,
            }))
        },
        [popupOpen]
    )

    const [history, setHistory] = useState<IRoundResult[]>([])
    const [oldHashAndKey, setOldHashAndKey] = useContext(OldHashAndKeyContext)

    // Control
    const [amount, setAmount] = useState<string>('0')
    const [targetMultiplier, setTargetMultiplier] = useState('1.01')

    const [isAutobet, setIsAutobet] = useState(false)
    const [isAutobetRunning, setIsAutobetRunning] = useState(false)

    useEffect(() => {
        if (isAutobet) {
            autoplaySound()
        } else {
            setIsAutobetRunning(false)
        }
    }, [isAutobet])

    const [numberOfRounds, setNumberOfRounds] = useState('10')

    const [isGame, setIsGame] = useState(false)
    const [odd, setOdd] = useState<number>(1)
    const [gameResult, setGameResult] = useState<GameResult>('')

    const [animationOddTimer, setAnimationOddTimer] = useState<NodeJS.Timer | null>(null)

    const handleInitialState = (data: InitialState) => {
        setBalance(data.balance)
        setInitialState(data)
    }

    const handleSpinResult = (data: SpinResult) => {
        if (data.responseCode > 0) {
            setErr(t('UNEXPECTEDERR'))
            return
        }

        setHistory(prev => [
            { odd: data.result.resultOdd, status: data.spinStatus },
            ...prev,
        ])
        setOldHashAndKey(data.oldResultHashAndKey)

        if (animationOddTimer) {
            clearInterval(animationOddTimer)
        }

        setOdd(data.result.resultOdd)

        if (data.spinStatus === 0) {
            crashSound()

            setGameResult('lose')
            setBalance(data.balance)
        }

        if (data.spinStatus === 1) {
            winSound()

            setGameResult('win')
            setBalance(data.balance + data.winAmount)
        }

        if (!isAutobet) {
            setIsGame(false)
        }
    }

    const stopAutobet = () => {
        setIsAutobet(false)
        setIsAutobetRunning(false)
        setIsGame(false)
    }

    const runAutoBet = async () => {
        if (!isAutobet || +numberOfRounds < 1) return

        if (+amount > balance) {
            setErr(t('NOBALANCE'))
            setIsAutobet(false)
            return
        }

        setIsAutobetRunning(true)

        setNumberOfRounds(prev => (+prev - 1).toString())

        await spin(+amount, +targetMultiplier)
    }

    useEffect(() => {
        if (!gameResult) return

        let timerId: NodeJS.Timeout | null = null

        if (isAutobet) {
            if (+numberOfRounds > 0) {
                timerId = setTimeout(() => runAutoBet(), 1000)
            } else {
                stopAutobet()
            }
        } else {
            stopAutobet()
        }

        return () => {
            if (timerId) {
                return clearTimeout(timerId)
            }
        }
    }, [gameResult])

    const spin = async (amount: number, odd: number) => {
        if (!connection) return

        if (amount > balance) {
            setErr(t('NOBALANCE'))
            return
        }

        if (amount > initialState.limits.maxBet || amount < initialState.limits.minBet) {
            return
        }

        if (odd < 1.01 || odd > 100000) {
            return
        }

        setGameResult('')
        setIsGame(true)

        betSound()

        setBalance(prev => prev - amount)

        const data = { amount, spinData: { odd } }

        if (isTurboMode) {
            await connection.invoke('Spin', data)
        } else {
            let counter = 0
            const delay = 50

            const timerId = setInterval(() => {
                setOdd(randomOdd())
                clickSound()
                counter += delay

                if (counter === 1000) {
                    connection.invoke('Spin', data)
                }
            }, delay)

            setAnimationOddTimer(timerId)
        }

    }

    useEffect(() => {
        const connect = async () => {
            let token: string | null = null

            if (oneTimeToken) {
                const { accessToken } = await Api().auth.login(oneTimeToken)
                token = accessToken
            }

            let wsUrl = process.env.REACT_APP_WS as string

            if (token) {
                wsUrl = wsUrl + '?access_token=' + token
            }

            const c = new HubConnectionBuilder()
                .withUrl(wsUrl)
                .withAutomaticReconnect()
                .build()
            setConnection(c)
        }
        connect()
    }, [])

    useEffect(() => {
        if (!connection) return

        const runConnection = async () => {
            connection.on('InitialStateResult', handleInitialState)

            await connection.stop()
            await connection.start()

            let initStateDto: Object = { partnerId, gameName }

            if (mode === 'demo') {
                initStateDto = { ...initStateDto, isDemo: true }
            }

            await connection.invoke("InitialState", initStateDto )
        }
        runConnection()
    }, [connection])

    useEffect(() => {
        if (!connection) return

        connection.off('SpinResult')
        connection.on('SpinResult', handleSpinResult)
    }, [connection, isMute, animationOddTimer, isAutobet])

    if (!Object.keys(initialState).length) {
        return (
            <div className="h-screen flex items-center justify-center">
                <img src={yo} alt="Yo" className="w-[200px]" />
            </div>
        )
    }

    return (
        <div className={s.game}>
            <PlayingField
                gameResult={gameResult}
                odd={odd}
                history={history}
            />
            <Control
                isGame={isGame}
                amount={amount}
                setAmount={setAmount}
                targetMultiplier={targetMultiplier}
                setTargetMultiplier={setTargetMultiplier}
                isAutobet={isAutobet}
                setIsAutobet={setIsAutobet}
                numberOfRounds={numberOfRounds}
                setNumberOfRounds={setNumberOfRounds}
                spin={spin}
                runAutobet={runAutoBet}
                isAutobetRunning={isAutobetRunning}
            />

            {oldHashAndKey && (
                <Popup
                    active={popupOpen.provablyFairness}
                    setActive={value => openPopup('provablyFairness', value)}
                >
                    <ProvablyFairnessModal lastRound={oldHashAndKey} />
                </Popup>
            )}

            <Popup
                active={popupOpen.rules}
                setActive={value => openPopup('rules', value)}
            >
                <RulesPopup />
            </Popup>
        </div>
    )
}

export default Game

export type GameResult = '' | 'win' | 'lose'
